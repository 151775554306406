// gets the base url from axios
import onboardingHttpClient from "@/axios/dashboard.service";

const state = {
  index: [],
  numberOfRows: 0,
  itemPerPage: 20,
  currentPage: 1,
}

const getters = {}

const actions = {
  List: (context) => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen'),
    }

    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/inbox/${state.itemPerPage}`, data).then(response => {
        context.commit('setNumbersOfRow', response.data.num_rows)
        resolve(response)
      }).then(error => {
        reject(error)
      })
    })
  },

  InboxRead : (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/inbox-read/${state.itemPerPage}`, data).then(response => {
        context.commit('setNumbersOfRow', response.data.num_rows)
        resolve(response)
      }).then(error => {
        reject(error)
      })
    })
  },

  InboxCategory : (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/inbox-category/${state.itemPerPage}`, data).then(response => {
        context.commit('setNumbersOfRow', response.data.num_rows)
        resolve(response)
      }).then(error => {
        reject(error)
      })
    })
  },

  Delete: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/inbox-delete-message`, data).then(response => {
        context.commit('donothing', response.data)
        resolve(response);
      }).then(error => {
        reject(error);
      })
    })
  },

  Read: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/inbox-set-message-as-read`, data).then(response => {
        context.commit('donothing', response.data)
        resolve(response);
      }).then(error => {
        reject(error);
      })
    })
  },

  CountUnRead: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/inbox-no-unread-messages`, data).then(response => {
        context.commit('donothing', response.data)
        resolve(response);
      }).then(error => {
        reject(error);
      })
    })
  },
}

const mutations = {
  // commit numbers of rows
  setNumbersOfRow: (state, data) => (state.numberOfRows = data),
  // increment itemPerScroll
  setItemPerPage: (state) => (state.itemPerPage += 10),
  decreaseItemPerPage: (state) => (state.itemPerPage -= 10),
  setCurrentPage: (state) => (state.currentPage += 1),
  returnItemToTen: (state, data) => (state.itemPerPage = data),
  returnToPageOne: (state, data) => (state.currentPage = data),
  removeCurrentPage: (state) => (state.currentPage -= 1),
  donothing: () => {

  }
}

export default {
  //export all the listed properties
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};