import Vue from "vue";
import Vuex from "vuex";
import onboarding from "./modules/onboarding";
import dashboard from "./modules/dashboard";
import documents from "./modules/documents";
import announcement from "./modules/announcement";
import promotion from "./modules/promotion";
import event from "./modules/event";
import trading from "./modules/trade";
import profile from "./modules/profile";
import account from "./modules/account";
import deposit from "./modules/deposit";
import withdrawal from "./modules/withdrawal";
import partnership from "./modules/partnership";
import kyc from "./modules/kyc";
import social from "./modules/social";
import inbox from "./modules/inbox";

// initial state
const initialState = {
  onboarding: onboarding.state,
  dashboard: dashboard.state,
  documents: documents.state,
  announcement: announcement.state,
  promotion: promotion.state,
  event: event.state,
  account: account.state,
  trading: trading.state,
  profile: profile.state,
  deposit: deposit.state,
  withdrawal: withdrawal.state,
  partnership: partnership.state,
  kyc: kyc.state,
  social: social.state,
  inbox: inbox.state,
};

//Convert object in string
const COPY = JSON.stringify(initialState);

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    onboarding: onboarding,
    dashboard: dashboard,
    documents: documents,
    announcement: announcement,
    promotion: promotion,
    event: event,
    account: account,
    trading: trading,
    profile: profile,
    deposit: deposit,
    withdrawal: withdrawal,
    partnership: partnership,
    kyc: kyc,
    social: social,
    inbox: inbox,
  },

  mutations: {
    reset(state) {
      //Convert string in object
      let copyState = JSON.parse(COPY);
      Object.keys(state).forEach(key => {
        Object.assign(state[key], copyState[key]);
      });
    }
  }
});
